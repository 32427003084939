import { createClient } from '@supabase/supabase-js'
import { Auth } from '@supabase/auth-ui-react'
import {
  // Import predefined theme
  ThemeSupa,
} from '@supabase/auth-ui-shared'

const supabase = createClient(
    process.env.REACT_APP_SUPABASE_URL,
    process.env.REACT_APP_SUPABASE_ANON_KEY
)

const Login = () => {
console.log("Login,",process.env.REACT_APP_SUPABASE_URL, process.env.REACT_APP_SUPABASE_ANON_KEY )
 return (
  <Auth
    supabaseClient={supabase}
    /* Apply predefined theme */
    appearance={{ theme: ThemeSupa }}
    providers = { [ 'google', 'twitter' ] }    
  />
 )
}

export default Login