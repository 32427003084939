import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/home';
import Login from './pages/login';

function App() {
  console.log("App:", process.env, process.env.REACT_APP_SUPABASE_URL, process.env.REACT_APP_SUPABASE_ANON_KEY)
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        </Routes>
        </Router>
  );
}

export default App;