import logo from '../logo.svg';
import '../App.css';
import { createClient } from '@supabase/supabase-js';
import { Auth } from '@supabase/auth-ui-react';
import { ThemeSupa } from '@supabase/auth-ui-shared';
import { useNavigate } from 'react-router-dom';


const supabase = createClient(
  "https://zczricqvexsutpxkwlyc.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InpjenJpY3F2ZXhzdXRweGt3bHljIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjM3MDYyMDUsImV4cCI6MjAzOTI4MjIwNX0.lltgp515_H81DymLZ1eZv7AWM2pEULOh3imn2O5INNE"
)

function Home() {
  const navigate = useNavigate();


    supabase.auth.onAuthStateChange(async (event) => {

        if (event !== "SIGNED_OUT") {
            // navigate to success page
            navigate('/')
        } else {
            // forward to root page
            navigate('/')
        }
    })
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <Auth supabaseClient={supabase} 
                    appearance ={{ theme: ThemeSupa}}
                    theme ="dark"
                    providers = { [ 'google', 'twitter' ] }    
                />
      </header>
    </div>
  );
}

export default Home;